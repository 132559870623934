/**
* Design Tokens
* Autogenerated from tokens.json.
* DO NOT EDIT!
*/

export const tokens = {
    breakpoint: {
        desktop: '980px',
        leftCol: '1140px',
        mobile: '320px',
        mobileLandscape: '480px',
        mobileMedium: '375px',
        phablet: '660px',
        tablet: '740px',
        wide: '1300px',
    },
    colour: {
        blue: {
            '1': '#003C60',
            '2': '#004E7C',
            '3': '#005689',
            '4': '#0077B6',
            '5': '#00B2FF',
            '6': '#90DCFF',
            '7': '#F1F8FC',
            '8': '#001536',
            '9': '#041F4A',
            '10': '#052962',
            '11': '#506991',
            '12': '#C1D8FC',
        },
        brown: {
            '1': '#2B2625',
            '2': '#3E3323',
            '3': '#574835',
            '4': '#6B5840',
            '5': '#866D50',
            '6': '#A1845C',
            '7': '#EACCA0',
            '8': '#E7D4B9',
            '9': '#EFE8DD',
            '10': '#FBF6EF',
        },
        gray: {
            '1': '#000000',
            '2': '#121212',
            '3': '#1A1A1A',
            '4': '#333333',
            '5': '#606060',
            '6': '#707070',
            '7': '#999999',
            '8': '#DCDCDC',
            '9': '#EDEDED',
            '10': '#F6F6F6',
            '11': '#FFFFFF',
        },
        green: {
            '1': '#185E36',
            '2': '#22874D',
            '3': '#58D08B',
            '4': '#0C7A73',
            '5': '#65A897',
            '6': '#69D1CA',
        },
        orange: {
            '1': '#672005',
            '2': '#8D2700',
            '3': '#C74600',
            '4': '#E05E00',
            '5': '#FF7F0F',
            '6': '#FF9941',
            '7': '#F9B376',
            '8': '#FEF9F5',
        },
        pink: {
            '1': '#510043',
            '2': '#650054',
            '3': '#7D0068',
            '4': '#BB3B80',
            '5': '#F37ABC',
            '6': '#FFABDB',
            '7': '#FEC8D3',
            '8': '#FEEEF7',
        },
        red: {
            '1': '#660505',
            '2': '#8B0000',
            '3': '#AB0613',
            '4': '#C70000',
            '5': '#FF5943',
            '6': '#FF9081',
            '7': '#FFBAC8',
            '8': '#FFF4F2',
        },
        special: {
            '1': '#222527',
            '2': '#303538',
            '3': '#3F464A',
            '4': '#595C5F',
            '5': '#9DA0A2',
            '6': '#ABC2C9',
            '7': '#E4E5E8',
            '8': '#EFF1F2',
            '9': '#2B2B2A',
            '10': '#B9300A',
            '11': '#FF663D',
            '12': '#EBE6E1',
            '13': '#F5F0EB',
        },
        yellow: {
            '1': '#F3C100',
            '2': '#FFD900',
            '3': '#FFE500',
        },
    },
    height: {
        ctaMedium: '44px',
        ctaSmall: '36px',
        ctaXSmall: '24px',
        iconMedium: '30px',
        iconSmall: '26px',
        iconXSmall: '20px',
        inputMedium: '44px',
        inputXSmall: '24px',
    },
    palette: {
        brand: {
            '100': '#001536',
            '300': '#041F4A',
            '400': '#052962',
            '500': '#0077B6',
            '600': '#506991',
            '800': '#C1D8FC',
        },
        brandAlt: {
            '200': '#F3C100',
            '300': '#FFD900',
            '400': '#FFE500',
        },
        culture: {
            '50': '#2B2625',
            '100': '#3E3323',
            '200': '#574835',
            '300': '#6B5840',
            /** @deprecated Use palette.culture.400 */
            '350': '#866D50',
            '400': '#866D50',
            '450': '#A1845C',
            '500': '#EACCA0',
            '600': '#E7D4B9',
            '700': '#EFE8DD',
            '800': '#FBF6EF',
        },
        error: {
            '400': '#C70000',
            '500': '#FF9081',
        },
        focus: {
            '400': '#0077B6',
        },
        labs: {
            '200': '#0C7A73',
            '300': '#65A897',
            '400': '#69D1CA',
        },
        lifestyle: {
            '100': '#510043',
            '200': '#650054',
            '300': '#7D0068',
            '400': '#BB3B80',
            '450': '#F37ABC',
            '500': '#FFABDB',
            '600': '#FEC8D3',
            '800': '#FEEEF7',
        },
        neutral: {
            '0': '#000000',
            '7': '#121212',
            '10': '#1A1A1A',
            '20': '#333333',
            '38': '#606060',
            '46': '#707070',
            '60': '#999999',
            '86': '#DCDCDC',
            '93': '#EDEDED',
            '97': '#F6F6F6',
            '100': '#FFFFFF',
        },
        news: {
            '100': '#660505',
            '200': '#8B0000',
            '300': '#AB0613',
            '400': '#C70000',
            '500': '#FF5943',
            '550': '#FF9081',
            '600': '#FFBAC8',
            '800': '#FFF4F2',
        },
        opinion: {
            '100': '#672005',
            '200': '#8D2700',
            /** @deprecated Use palette.opinion.400 */
            '300': '#C74600',
            '400': '#C74600',
            '450': '#E05E00',
            '500': '#FF7F0F',
            '550': '#FF9941',
            '600': '#F9B376',
            '800': '#FEF9F5',
        },
        specialReport: {
            '100': '#222527',
            '200': '#303538',
            '300': '#3F464A',
            '400': '#595C5F',
            '450': '#9DA0A2',
            '500': '#ABC2C9',
            '700': '#E4E5E8',
            '800': '#EFF1F2',
        },
        specialReportAlt: {
            '100': '#2B2B2A',
            '200': '#B9300A',
            '300': '#FF663D',
            '700': '#EBE6E1',
            '800': '#F5F0EB',
        },
        sport: {
            '100': '#003C60',
            '200': '#004E7C',
            '300': '#005689',
            '400': '#0077B6',
            '500': '#00B2FF',
            '600': '#90DCFF',
            '800': '#F1F8FC',
        },
        success: {
            '400': '#22874D',
            '500': '#58D08B',
        },
    },
    size: {
        icon: {
            medium: '30px',
            small: '26px',
            xSmall: '20px',
        },
        medium: '44px',
        small: '36px',
        xSmall: '24px',
    },
    space: {
        '0': '2px',
        '1': '4px',
        '2': '8px',
        '3': '12px',
        '4': '16px',
        '5': '20px',
        '6': '24px',
        '8': '32px',
        '9': '36px',
        '10': '40px',
        '12': '48px',
        '14': '56px',
        '16': '64px',
        '18': '72px',
        '24': '96px',
    },
    typography: {
        body: {
            medium: {
                fontFamily: ['GuardianTextEgyptian', 'Guardian Text Egyptian Web', 'Georgia', 'serif'],
                fontSize: '17px',
                lineHeight: 1.4,
                fontWeight: 400,
            },
            small: {
                fontFamily: ['GuardianTextEgyptian', 'Guardian Text Egyptian Web', 'Georgia', 'serif'],
                fontSize: '15px',
                lineHeight: 1.4,
                fontWeight: 400,
            },
            xSmall: {
                fontFamily: ['GuardianTextEgyptian', 'Guardian Text Egyptian Web', 'Georgia', 'serif'],
                fontSize: '14px',
                lineHeight: 1.4,
                fontWeight: 400,
            },
        },
        fontFamily: {
            body: ['GuardianTextEgyptian', 'Guardian Text Egyptian Web', 'Georgia', 'serif'],
            headline: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
            textSans: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
            titlepiece: ['GT Guardian Titlepiece', 'Georgia', 'serif'],
        },
        fontSize: {
            '12': '12px',
            '14': '14px',
            '15': '15px',
            '17': '17px',
            '20': '20px',
            '24': '24px',
            '28': '28px',
            '34': '34px',
            '42': '42px',
            '50': '50px',
            '70': '70px',
        },
        fontWeight: {
            bold: 700,
            light: 300,
            medium: 500,
            regular: 400,
        },
        headline: {
            large: {
                fontFamily: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
                fontSize: '42px',
                lineHeight: 1.15,
                fontWeight: 500,
            },
            medium: {
                fontFamily: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
                fontSize: '34px',
                lineHeight: 1.15,
                fontWeight: 500,
            },
            small: {
                fontFamily: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
                fontSize: '28px',
                lineHeight: 1.15,
                fontWeight: 500,
            },
            xLarge: {
                fontFamily: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
                fontSize: '50px',
                lineHeight: 1.15,
                fontWeight: 500,
            },
            xSmall: {
                fontFamily: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
                fontSize: '24px',
                lineHeight: 1.15,
                fontWeight: 500,
            },
            xxSmall: {
                fontFamily: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
                fontSize: '20px',
                lineHeight: 1.15,
                fontWeight: 500,
            },
            xxxSmall: {
                fontFamily: ['GH Guardian Headline', 'Guardian Egyptian Web', 'Georgia', 'serif'],
                fontSize: '17px',
                lineHeight: 1.15,
                fontWeight: 500,
            },
        },
        lineHeight: {
            loose: 1.4,
            regular: 1.3,
            tight: 1.15,
        },
        textDecorationThickness: {
            '2': '2px',
            '3': '3px',
            '4': '4px',
            '5': '5px',
            '6': '6px',
        },
        textSans: {
            large: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '20px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
            medium: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '17px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
            small: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '15px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
            xLarge: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '24px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
            xSmall: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '14px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
            xxLarge: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '28px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
            xxSmall: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '12px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
            xxxLarge: {
                fontFamily: ['GuardianTextSans', 'Guardian Text Sans Web', 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'sans-serif'],
                fontSize: '34px',
                lineHeight: 1.3,
                fontWeight: 400,
            },
        },
        titlepiece: {
            large: {
                fontFamily: ['GT Guardian Titlepiece', 'Georgia', 'serif'],
                fontSize: '70px',
                lineHeight: 1.15,
                fontWeight: 700,
            },
            medium: {
                fontFamily: ['GT Guardian Titlepiece', 'Georgia', 'serif'],
                fontSize: '50px',
                lineHeight: 1.15,
                fontWeight: 700,
            },
            small: {
                fontFamily: ['GT Guardian Titlepiece', 'Georgia', 'serif'],
                fontSize: '42px',
                lineHeight: 1.15,
                fontWeight: 700,
            },
        },
    },
    width: {
        ctaMedium: '44px',
        ctaSmall: '36px',
        ctaXSmall: '24px',
        iconMedium: '30px',
        iconSmall: '26px',
        iconXSmall: '20px',
        inputXSmall: '24px',
    },
};
