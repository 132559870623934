import { onConsentChange } from '@guardian/libs';
let initialConsentState;
/**
 * If consent has been set, and if consent then changes, reload the page so the correct
 * state is reflected in the rendered page
 */
const reloadPageOnConsentChange = () => {
    onConsentChange((consent) => {
        if (initialConsentState === undefined) {
            initialConsentState = consent;
        }
        if (initialConsentState.canTarget !== consent.canTarget) {
            window.location.reload();
        }
    }, true);
    return Promise.resolve();
};
export { reloadPageOnConsentChange };
